@import '../../variables.module.scss';

.contact {
    background-color: $white-color;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    text-align: center;
    justify-content: space-evenly;

    h3{
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 35px;
        color: $blue-color;
        width: 100%;
        text-decoration: underline;
        text-align: center;
    }

.contDets{    
    width: 100%;

    h4 {
        color: $black-color;
        font-size: 20px;
    }

    a{
        text-decoration: none;
    }

    p {
        margin: 5px;
        color: $blue-color;
        font-size: 15px;
        font-weight: bold;
    }
}

.iconLinks {
    display: flex;
    justify-content: center; 
        a {
            color: $blue-color;
            margin: 10px;
            font-size: 75px;
        }
    }
}

@media (min-width:700px){
    .contact {
            margin-left: 20%;
            width: auto;
            height: 100vh;

            h3{
                font-size: 50px;
                margin: 0px;
            }


        .contDets{
            text-align: center;

            h4{
                font-size: 30px;
            }

            p{
                font-size: 20px;
            }

            a p:hover{
                color: $orange-color;
                transition: 0.6s ease;
            }
        }

        .iconLinks{
            a:hover{
                color: $orange-color;
                transition: 0.75s ease;
            }
        }
    }
}

@media(min-width: 2000px) {
    .contact {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
    
        h3{
            font-size: 75px;
        }
    
        .contDets{        
            h4 {
                font-size: 45px;
            }    
            a{
                text-decoration: none;
            }    
            p {
                font-size: 30px;
                font-weight: bold;
            }
        }
        
        .iconLinks {
            a {
                font-size: 100px;
            }
        }
    }
}