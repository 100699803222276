@import '../../variables.module.scss';

.leftNav {
        display: none;
}
    

@media (min-width:700px){
    .leftNav {
        flex-direction: column;  
        width: 20%;
        height: 100vh;
        background-color: $blue-color;
        justify-content: center;
        align-items: center;
        border-right: 5px solid $orange-color;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        display: flex;
    }
        

    ul li {
        margin: 30px;
        padding: 10px;
        list-style: none;
        cursor: pointer;
    }
            
    ul li a {
        color: $white-color;
        font-size: 20px;
        text-decoration: none;
    }
            
    ul li a:hover {
            color: $orange-color;
            transition: 0.6s ease;
    }
}  

@media (min-width: 2000px){            
    ul li a {
        font-size: 50px;
    }
}
