@import '../../variables.module.scss';

.mobileNav{
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $blue-color;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 3px solid $orange-color;
    z-index: 999;
    
    .navItems{
        width: 100%;
        display: flex;
        justify-content: space-around;
        
        li{
            list-style: none;
        }

        a {
            text-decoration: none;
            color: $white-color;

        }
    }    
}

@media (min-width: 450px){

    .navItems li a {
        font-size: 30px;

    }
}

@media (min-width:700px){
    .mobileNav {
        display: none;
    }
}
