@import '../../variables.module.scss';

.home {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: $grey-color;
    color: $white-color;

    div {
        display: flex;
        flex-wrap: wrap;
        padding-left: 15px;
    }

    h1 {
        font-size: 50px;
        letter-spacing: 4px;                
    }

    h2{
        font-size: 30px;
        letter-spacing: 2px;
    }
    
    a{  
        display: block;
        width: 100%;
        color: $white-color;
        text-decoration: none;
        font-size: 20px;
        margin-top: 10px;
    }

}

@media (min-width:330px){
    h1, h2, button {
        margin: auto;
        width: 100%;
    }    
}

@media (min-width: 450px){
    .home div {
        display: block;
    }
}
@media (min-width:700px){
    body .mobileNav {
        display: none;
    }

    .home {
        margin-left: 20%;
        width: auto;

        .mobOnly {
            display: none;
        }

        a {
            text-decoration: underline;
            &:hover {
                color: $orange-color;
                transition: 0.7s ease;
                cursor: pointer;
            }
        }

    }
}

@media (min-width: 2000px){ 
    .home{
        h1{
            font-size: 75px;
        }
        h2{
            font-size: 50px;
        }
        a{
            font-size: 35px;
        }
    }              
}