@import url(https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Prompt, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{margin:0;padding:0;box-sizing:border-box}html{font-family:"Prompt",sans-serif;scroll-behavior:smooth}section{max-width:100%;overflow-x:hidden}

.MobileNav_mobileNav__28z0b{height:10%;width:100%;display:flex;align-items:center;background-color:#4B4E6D;position:fixed;bottom:0;left:0;border-top:3px solid #F08700;z-index:999}.MobileNav_mobileNav__28z0b .MobileNav_navItems__nRB1h{width:100%;display:flex;justify-content:space-around}.MobileNav_mobileNav__28z0b .MobileNav_navItems__nRB1h li{list-style:none}.MobileNav_mobileNav__28z0b .MobileNav_navItems__nRB1h a{text-decoration:none;color:#fff}@media (min-width: 450px){.MobileNav_navItems__nRB1h li a{font-size:30px}}@media (min-width: 700px){.MobileNav_mobileNav__28z0b{display:none}}

.DesktopNav_leftNav__Bw38T{display:none}@media (min-width: 700px){.DesktopNav_leftNav__Bw38T{flex-direction:column;width:20%;height:100vh;background-color:#4B4E6D;justify-content:center;align-items:center;border-right:5px solid #F08700;position:fixed;top:0;left:0;z-index:999;display:flex}ul li{margin:30px;padding:10px;list-style:none;cursor:pointer}ul li a{color:#fff;font-size:20px;text-decoration:none}ul li a:hover{color:#F08700;transition:0.6s ease}}@media (min-width: 2000px){ul li a{font-size:50px}}

.Home_home__aP8ao{width:100%;display:flex;justify-content:center;align-items:center;height:100vh;background-color:#95A3B3;color:#fff}.Home_home__aP8ao div{display:flex;flex-wrap:wrap;padding-left:15px}.Home_home__aP8ao h1{font-size:50px;letter-spacing:4px}.Home_home__aP8ao h2{font-size:30px;letter-spacing:2px}.Home_home__aP8ao a{display:block;width:100%;color:#fff;text-decoration:none;font-size:20px;margin-top:10px}@media (min-width: 330px){h1,h2,button{margin:auto;width:100%}}@media (min-width: 450px){.Home_home__aP8ao div{display:block}}@media (min-width: 700px){body .Home_mobileNav__jNHO2{display:none}.Home_home__aP8ao{margin-left:20%;width:auto}.Home_home__aP8ao .Home_mobOnly__2WwPB{display:none}.Home_home__aP8ao a{text-decoration:underline}.Home_home__aP8ao a:hover{color:#F08700;transition:0.7s ease;cursor:pointer}}@media (min-width: 2000px){.Home_home__aP8ao h1{font-size:75px}.Home_home__aP8ao h2{font-size:50px}.Home_home__aP8ao a{font-size:35px}}

.Bio_bio__306h-{background-color:#fff;display:flex;flex-direction:column;align-items:center;flex-wrap:wrap;color:#000;height:auto;margin-bottom:50px}.Bio_bio__306h- .Bio_aboutHeader__36fO3{display:flex;margin-top:5px;justify-content:center}.Bio_bio__306h- .Bio_aboutHeader__36fO3 h3{margin:20px;font-size:35px;align-self:center;text-decoration:underline}.Bio_bio__306h- .Bio_aboutHeader__36fO3 img{height:125px;width:150px;border-radius:50%}.Bio_bio__306h- p{padding:15px}.Bio_bio__306h- .Bio_skillsIcons__29Iu1{display:flex;width:100%;justify-content:space-evenly}.Bio_bio__306h- .Bio_skillsIcons__29Iu1 span{font-size:35px;margin:5px}@media (min-width: 700px){.Bio_bio__306h-{margin-left:20%;margin-top:10px;margin-bottom:0px;width:auto}}@media (min-width: 900px){.Bio_bio__306h- .Bio_skillsIcons__29Iu1 span{font-size:50px}.Bio_bio__306h- p{font-size:20px}}@media (min-width: 2000px){.Bio_bio__306h- .Bio_aboutHeader__36fO3{display:flex;margin-top:5px;justify-content:center}.Bio_bio__306h- .Bio_aboutHeader__36fO3 h3{margin:20px;font-size:75px;align-self:center;text-decoration:underline}.Bio_bio__306h- .Bio_aboutHeader__36fO3 img{height:350px;width:450px}.Bio_bio__306h- p{font-size:35px}.Bio_bio__306h- .Bio_skillsIcons__29Iu1 span{font-size:100px}}

.Card_card__3e0Xs{border-top-right-radius:10px;border-top-left-radius:10px;height:auto;width:90%;margin:auto;margin-bottom:45px;color:#000;background-color:#fff;box-shadow:0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(5,5,5,0.19)}.Card_card__3e0Xs .Card_cardImg__3c76e{width:100%;border-radius:10px}.Card_card__3e0Xs .Card_cardBody__3Pbq7{display:flex;flex-direction:column;padding:20px}.Card_card__3e0Xs .Card_cardBody__3Pbq7 .Card_cardTitle__2RqaZ{font-size:20px;margin:5px}.Card_card__3e0Xs .Card_cardBody__3Pbq7 .Card_cardText__2dGbD{font-size:15px;margin:5px}.Card_card__3e0Xs .Card_cardBody__3Pbq7 .Card_cardLinks__2a5Ha{display:flex;justify-content:space-around;margin:5px}.Card_card__3e0Xs .Card_cardBody__3Pbq7 .Card_cardLinks__2a5Ha a{text-decoration:none;color:#4B4E6D;padding:5px;border-radius:10px;font-size:35px}@media (min-width: 550px){.Card_card__3e0Xs{width:40%;min-height:450px}.Card_card__3e0Xs .Card_cardBody__3Pbq7 .Card_cardLinks__2a5Ha a:hover{color:#F08700}}

.ProjectsPage_projects__1Ntdh{background-color:rgba(149,163,179,0.514);padding-top:20px;margin-bottom:20px}.ProjectsPage_projects__1Ntdh h3{font-size:35px;color:#4B4E6D;text-align:center;width:100%;text-decoration:underline;margin-bottom:20px}.ProjectsPage_projects__1Ntdh .ProjectsPage_cardContainer__3gfmi{display:flex;flex-wrap:wrap}@media (min-width: 700px){.ProjectsPage_projects__1Ntdh{margin-left:20%;margin-bottom:0px}}

.Contact_contact__352r2{background-color:#fff;padding:20px;display:flex;flex-wrap:wrap;height:100vh;text-align:center;justify-content:space-evenly}.Contact_contact__352r2 h3{margin-top:10px;margin-bottom:10px;font-size:35px;color:#4B4E6D;width:100%;text-decoration:underline;text-align:center}.Contact_contact__352r2 .Contact_contDets__1oamB{width:100%}.Contact_contact__352r2 .Contact_contDets__1oamB h4{color:#000;font-size:20px}.Contact_contact__352r2 .Contact_contDets__1oamB a{text-decoration:none}.Contact_contact__352r2 .Contact_contDets__1oamB p{margin:5px;color:#4B4E6D;font-size:15px;font-weight:bold}.Contact_contact__352r2 .Contact_iconLinks__1tVeP{display:flex;justify-content:center}.Contact_contact__352r2 .Contact_iconLinks__1tVeP a{color:#4B4E6D;margin:10px;font-size:75px}@media (min-width: 700px){.Contact_contact__352r2{margin-left:20%;width:auto;height:100vh}.Contact_contact__352r2 h3{font-size:50px;margin:0px}.Contact_contact__352r2 .Contact_contDets__1oamB{text-align:center}.Contact_contact__352r2 .Contact_contDets__1oamB h4{font-size:30px}.Contact_contact__352r2 .Contact_contDets__1oamB p{font-size:20px}.Contact_contact__352r2 .Contact_contDets__1oamB a p:hover{color:#F08700;transition:0.6s ease}.Contact_contact__352r2 .Contact_iconLinks__1tVeP a:hover{color:#F08700;transition:0.75s ease}}@media (min-width: 2000px){.Contact_contact__352r2{display:flex;flex-direction:column;text-align:center;justify-content:center}.Contact_contact__352r2 h3{font-size:75px}.Contact_contact__352r2 .Contact_contDets__1oamB h4{font-size:45px}.Contact_contact__352r2 .Contact_contDets__1oamB a{text-decoration:none}.Contact_contact__352r2 .Contact_contDets__1oamB p{font-size:30px;font-weight:bold}.Contact_contact__352r2 .Contact_iconLinks__1tVeP a{font-size:100px}}

