@import './variables.module.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    font-family: $main-font, $secondary-font;
    scroll-behavior: smooth;
}

section{
    max-width: 100%;
    overflow-x: hidden;
}