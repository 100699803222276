@import '../../variables.module.scss';

.projects {
    background-color: rgba(149, 163, 179, 0.514);
    padding-top: 20px;
    margin-bottom: 20px;

    h3{
        font-size: 35px;
        color: $blue-color;
        text-align: center;
        width: 100%;
        text-decoration: underline;
        margin-bottom: 20px;
    }

    .cardContainer {
        display: flex;
        flex-wrap: wrap;
    }
}

@media (min-width:700px) {
    .projects {
        margin-left: 20%;
        margin-bottom: 0px;
    }
}

